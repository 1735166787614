@use "sass:math";

@function em($pixels, $context: 15) {
    @return #{math.div($pixels, $context)}em;
}
@function rem($pixels, $context: 15) {
    @return #{math.div($pixels, $context)}rem;
}
@function lineheight($pixels, $context: 15) {
    @return #{math.div($pixels, $context)};
}

$font: Roboto,sans-serif;
$font_weight_normal: 300;
$font_weight_bold: 400;

$color_font: #5f5f5f;
$color_green: #6D9387;

$breakpoint_1: 600px;
