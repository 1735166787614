@import 'includes/variables';
@import 'includes/media-queries';
@import 'includes/fonts';
@import 'includes/basic';
@import '../../node_modules/awesome-notifications/dist/style';

.awn-toast-progress-bar {
    display: none;
}

#awn-popup-wrapper .awn-popup-modal-download {
    font-size: rem(16);
    text-align: center;
    font-weight: $font_weight_bold;

    a {
        color: white;
    }
}


#header {
    background-image: url("../img/header.jpg");
    background-size: cover;
    background-position: center;
    padding-top: 70px;
    padding-bottom: 90px;
    //min-height: 37vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-shrink: 0;
    position: relative;

    @include max-screen($breakpoint_1){
        padding-top: 50px;
    }

    .inner {
        color: $color_green;
        text-align: center;
        max-width: 730px;
    }

    .pagetitle {
        text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
        font-weight: $font_weight_bold;
        font-size: rem(35);
        line-height: lineheight(45, 35);
        text-transform: uppercase;

        @include max-screen($breakpoint_1){
            font-size: rem(25);
        }

        a {
            text-decoration: none;
        }
    }
    #headerbutton {
        background-color: $color_green;
        color: white;
        text-transform: uppercase;
        width: 660px;
        max-width: 100%;
        font-size: rem(20);
        font-weight: $font_weight_normal;
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        text-align: center;
        padding: 16px 15px;
        box-sizing: border-box;
    }
}


#content {
    padding: 80px 0 50px 0;
    flex-grow: 1;

    @include max-screen($breakpoint_1){
        padding-top: 50px;
    }
}
.inner.startinner {
    max-width: 590px;
}

#footer {
    background-color: $color_green;
    color: white;
    padding: 30px 50px;
    flex-shrink: 0;

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            display: inline-block;
            position: relative;
            padding-right: 10px;
            margin-right: 10px;

            &:after {
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate3d(0, -50%, 0);
                width: 1px;
                background-color: white;
                height: 12px;
            }

            &:last-child {
                padding-right: 0;
                margin-right: 0;

                &:after {
                    display: none;
                }
            }

            a {
                color: white;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

ol#manual {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        margin-bottom: 20px;
        position: relative;

        .number {
            border: 2px solid $color_green;
            width: 20px;
            height: 20px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: bold;
            color: $color_green;
            border-radius: 50%;
            position: absolute;
            left: -34px;
            top: -3px;
        }
    }
}

#codeform {
    max-width: 330px;
    margin: 40px auto 0 auto;

    .formrow {
        margin-bottom: 18px;
    }

    input[type="text"],
    input[type="number"] {
        border: 1px solid $color_font;
        color: $color_font;
        font-size: rem(14);
        box-sizing: border-box;
        width: 100%;
        text-align: center;
        padding: 11px 15px;
        text-transform: uppercase;
    }

    .button {
        width: 100%;
        display: block;
    }
}


#logos {
    text-align: center;
    margin-bottom: 50px;

    a {
        text-decoration: none;
    }

    img {
        width: 130px;
        margin: 0 20px 20px 20px;
        display: inline-block;
        vertical-align: middle;

        @include max-screen($breakpoint_1){
            display: block;
            margin: 0 auto 30px auto;
        }
    }

    #logo_pwd {
        width: 270px;
    }
    #logo_bencard {
        width: 130px;
    }
    #logo_aerztewoche {
        width: 200px;
    }
}

#footerlogo {
    text-align: center;
    border-top: 2px solid rgba($color_font, 0.5);
    margin-top: 40px;
    padding-top: 40px;

    #logo_pwd {
        width: 280px;
    }
}
