* {
    margin: 0;
    padding: 0;
}

html {
    font-size: 15px;
    height: 100%;
}
body {
    font-family: $font;
    color: $color_font;
    line-height: lineheight(20);
    font-weight: $font_weight_normal;

    display: flex;
    flex-direction: column;
    height: 100%;
}

a img {
    border: none;
}

p {
    margin-bottom: 20px;
}

ul {
    padding-left: 18px;
    margin-bottom: 15px;
}

ol {
    padding-left: 18px;
    margin-bottom: 15px;
}

strong {
    font-weight: $font_weight_bold;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    color: $color_green;
}

.green {
    color: $color_green;
}

.invisible {
    position: absolute;
    left: -9999999px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.inner {
    margin: 0 auto;
    position: relative;
    max-width: 660px;
    padding: 0 40px;
}

h1, .h1 {
    color: $color_green;
    font-weight: $font_weight_normal;
    font-size: rem(30);
    margin-bottom: 15px;

}


.button {
    -webkit-appearance: none;
    text-decoration: none;
    background-color: rgba($color_green, 1);
    color: white;
    text-transform: uppercase;
    font-size: rem(15);
    font-weight: $font_weight_normal;
    text-align: center;
    padding: 12px 15px;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    font-family: $font;
    display: inline-block;
    transition: background-color 0.1s linear;

    &:hover {
        background-color: rgba($color_green, 0.8);
    }
}
